<template>
  <div>
    <div v-if="studentDeleted" class="alert alert-warning">Student associated with this information card has been marked as deleted, editing information about this student will not be reflected in active student record.</div>
    <transition mode="out-in">
      <information-card-edit
        v-if="mode === 'edit'"
        :year="year"
        :id="id"
        :instance="information_card"
        :member_id="member_id"
        :auth_id="auth_id"
        :program="program"
        :program-slug="programSlug"
        :return-to="returnTo"
        :can-edit-program-year="canEditProgramYear"
        :enrollment-types="enrollmentTypes"
        :source-information-card="sourceInformationCard"
        v-on:complete="editComplete"
        v-on:cancel="cancel()"
        v-on:program-selected="updateSlug"
        v-on:year-selected="updateYear"
        key="edit"
      />
      <information-card-view
        v-else-if="mode === 'view'"
        key="view"
        :year="year"
        :id="id"
        :member_id="member_id"
        :auth_id="auth_id"
        :return-to="returnTo"
        :editable="allowEdit"
        v-on:edit="switchMode('edit')"
        v-on:cancel="back"
      />
      <div v-else class="alert alert-info" key="switch">
        Changing Mode
      </div>
    </transition>
    <modal name="next-view" height="auto" :scrollable="true" width="80%">
      <next-view
        :information_card="information_card"
        :member_id="member_id"
        :title="'Pick next actions with ' + cardName"
        :subtitle="
          'You have created a new student and information card for ' + cardName
        "
        @shouldClose.prevent="hide('next-view')"
      />
    </modal>
    <modal
      name="redirect-wait"
      height="auto"
      :scrollable="true"
      width="80%"
      :clickToClose="false"
    >
      <div class="alert alert-info">
        <h2 class="text-center">
          <cs-loading>
            Redirecting to <span v-html="redirectTitle" />
          </cs-loading>
        </h2>
      </div>
    </modal>
  </div>
</template>

<script>

import { API, getQueryVariable, urlBuilder } from '@/utilities/http-common'
import { getInformationCard } from '@/api/informationCardAPI'
import InformationCard from '@/models/InformationCard'
import School from '@/models/School'
import Grade from '@/models/Grade'
import Program from '@/models/Program'
import Teacher from '@/models/Teacher'
import Member from '@/models/Member'
import Contact from '@/models/Contact'
import isEmpty from 'lodash/isEmpty'
import { mapGetters, mapMutations } from 'vuex'
import { UPDATE_STATES } from '@/constants/mutation-types'

export default {
  name: 'InformationCard',
  components: {
    informationCardEdit: () => import(/* webpackChunkName: "InformationCardEdit" */ '@/components/information_card/InformationCardEdit'),
    informationCardView: () => import(/* webpackChunkName: "InformationCardView" */ '@/components/information_card/InformationCardView'),
    NextView: import(/* webpackChunkName: "NextView" */ '@/components/information_card/NextView')
  },
  props: {
    card_id: {
      type: Number,
      default: -1,
      required: false
    },
    card: {
      type: Object,
      required: false,
      default: () => {}
    },
    contacts: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    student: {
      type: Object,
      required: false,
      default: () => {}
    },
    states: {
      type: Object,
      required: false,
      default: () => {}
    },
    enrollmentTypes: {
      type: Object,
      required: false,
      default: () => { }
    },
    member: {
      type: Object,
      required: true
    },
    authUser: {
      type: Object,
      required: false,
      default: () => {}
    },
    initialMode: {
      type: String,
      required: false,
      default: 'view'
    },
    allowMemberEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    currentYear: {
      type: Number,
      required: false,
      default: 0
    },
    returnTo: {
      type: String,
      required: false,
      default: '/'
    }
  },
  data () {
    return {
      mode: this.initialMode,
      year: 0,
      id: this.card_id,
      isNew: false,
      next: null,
      programSlug: '',
      programID: '',
      redirectTitle: 'Home Page',
      registration_id: 0,
      canEditProgramYear: false,
      member_id: null,
      auth_id: null,
      sourceInformationCardId: 0
    }
  },
  created () {
    if (typeof this.$attrs.year !== 'undefined') {
      this.year = parseInt(this.$attrs.year)
    }
    this.initModels()
    this.initiSourceInformationCard()
    if (
      typeof this.$attrs.NEXT !== 'undefined'
    ) {
      this.next = this.$attrs.NEXT
    }
    if (typeof this.$attrs.programSlug !== 'undefined') {
      this.programSlug = this.$attrs.programSlug
    } else {
      const test = getQueryVariable('program')
      if (!isEmpty(test)) {
        this.programSlug = test
      }
    }
    if (typeof this.$attrs.registration_id !== 'undefined') {
      this.registration_id = this.$attrs.registration_id
    } else {
      const registrationId = getQueryVariable('registration_id')
      if (!isEmpty(registrationId)) {
        this.registration_id = registrationId
      }
    }
    if (typeof this.$attrs.enrollment_types !== 'undefined') {
      this.enrollment_types = this.$attrs.enrollment_types
    }
    if (typeof this.$attrs !== 'undefined') {
      if (typeof this.$attrs.canEditProgramYear !== 'undefined') {
        // if program and year are defined in the url don't let these be reset
        if (parseInt(this.year) > 0 && this.programSlug !== '') {
          this.canEditProgramYear = false
        } else {
          this.canEditProgramYear = this.$attrs.canEditProgramYear
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isManager: 'gteManager'
    }),
    infoCardExists () {
      return isEmpty(this.information_card.student_id + '')
    },
    studentDeleted () {
      if (!isEmpty(this.information_card)) {
        if (this.information_card.id !== null && this.information_card.student_id !== null && !isEmpty(this.information_card.student_id + '') && isEmpty(this.information_card.student)) {
          return true
        }
      }
      return false
    },
    allowEdit () {
      if (this.isManager) {
        return true
      }
      return this.allowMemberEdit
    },
    cardName () {
      if (
        isEmpty(this.information_card) ||
        isEmpty(this.information_card.name)
      ) {
        return 'name to be set'
      } else {
        return this.information_card.name
      }
    },
    information_card () {
      if (this.id > 0) {
        const ic = InformationCard.query()
          .whereId(this.id)
          .with('information_card_contacts.*')
          .with('information_card_medicals.*')
          .with('student.*')
          .with('teacher')
          .with('site')
          .with('signatures')
          .first()
        ic.school_object = School.query()
          .where('abbreviation', ic.school)
          .first()
        ic.grade_object = Grade.query()
          .where('slug', ic.grade)
          .first()
        ic.signatures.sort(function (signature1, signature2) {
          return signature1.created < signature2.created ? 1 : -1
        })
        return ic
      } else {
        return {}
      }
    },
    sourceInformationCard () {
      if (this.sourceInformationCardId > 0) {
        const ic = InformationCard.query()
          .whereId(this.sourceInformationCardId)
          .with('information_card_contacts.*')
          .with('information_card_medicals.*')
          .with('student.*')
          .with('teacher')
          .with('site')
          .with('signatures')
          .first()
        ic.school_object = School.query()
          .where('abbreviation', ic.school)
          .first()
        ic.grade_object = Grade.query()
          .where('slug', ic.grade)
          .first()
        ic.signatures.sort(function (signature1, signature2) {
          return signature1.created < signature2.created ? 1 : -1
        })
        return ic
      } else {
        return {}
      }
    },
    program () {
      if (this.programSlug !== '') {
        return Program.query()
          .where('slug', this.programSlug)
          .first()
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapMutations({
      setStates: UPDATE_STATES
    }),
    initiSourceInformationCard () {
      const url = new URL(window.location.href)
      if (url.searchParams.has('source_id')) {
        const vm = this
        const sourceId = parseInt(url.searchParams.get('source_id'))
        getInformationCard(sourceId)
          .then(data => {
            console.dir('InformationCard.getSource.data:', data)
            InformationCard.create({
              data: data
            })
            this.$nextTick(() => {
              vm.sourceInformationCardId = sourceId
            })
          })
      }
    },
    initModels () {
      const initialMode = this.mode
      if (typeof this.member !== 'undefined') {
        const member = this.member
        this.member_id = member.id
        Member.insert({ data: member })
      }
      if (typeof this.authUser !== 'undefined') {
        const authUser = this.authUser
        this.auth_id = authUser.id
        Member.insert({ data: authUser })
      }
      if (!isEmpty(this.card)) {
        console.warn('info_card', typeof this.card)
        console.dir(this.card)
        InformationCard.create({
          data: this.card
        })
        this.id = this.card.id
        this.mode = initialMode
      } else if (typeof this.id !== 'undefined' && this.id !== -1) {
        this.id = this.card_id
        getInformationCard(this.id)
          .then(data => {
            console.dir('InformationCard.getInformationCard:', data)
            this.mode = initialMode
          })
      } else {
        this.isNew = true
        this.mode = 'edit'
        this.loadStudentInitialState()
      }
      if (this.contacts.length > 0) {
        const memberContacts = this.contacts
        memberContacts.forEach(contact => {
          Contact.insertOrUpdate({ data: contact })
        })
      }
      if (typeof this.$attrs.program !== 'undefined') {
        this.programID = this.$attrs.program.id
        Program.insertOrUpdate({ data: this.$attrs.program })
      }
      if (typeof this.$attrs.programs !== 'undefined') {
        Program.insertOrUpdate({ data: this.$attrs.programs })
      }
      if (typeof this.$attrs.teachers !== 'undefined') {
        const teachers = this.$attrs.teachers
        Teacher.insertOrUpdate({ data: teachers })
      }
      if (typeof this.$attrs.schools !== 'undefined') {
        const schools = this.$attrs.schools
        School.insertOrUpdate({ data: schools })
      }
      if (typeof this.$attrs.states !== 'undefined') {
        const asObject = this.$attrs.states
        const asArray = []
        for (const state in asObject) {
          if (Object.prototype.hasOwnProperty.call(asObject, state)) {
            asArray.push({ key: state, value: asObject[state] })
          }
        }
        this.setStates(asArray)
      }
      if (typeof this.$attrs.grades !== 'undefined') {
        const grades = this.$attrs.grades
        Grade.insertOrUpdate({ data: grades })
      }
    },
    loadStudentInitialState () {
      if (!isEmpty(this.student)) {
        const student = this.student
        this.information_card.student_id = student.id
        this.information_card.student_first = student.first
        this.information_card.student_last = student.last
        this.information_card.address = student.address
        this.information_card.city = student.city
        this.information_card.state = student.state
        this.information_card.zip = student.zip
        this.information_card.home_phone = student.phone
        this.information_card.grade = student.grade
        this.information_card.school = student.school
        this.information_card.student = student
      }
    },
    switchMode (mode) {
      this.mode = 'switch'
      this.$nextTick(function () {
        this.mode = mode
      })
    },
    updateYear (year) {
      this.$set(this, 'year', year)
      this.$emit('year-selected', year)
    },
    updateSlug (slug) {
      this.$set(this, 'programSlug', slug)
      this.$emit('program-selected', slug)
    },
    show (name) {
      this.$modal.show(name)
    },
    hide (name) {
      this.$modal.hide(name)
    },
    editCompleteWithOptions (options) {
      console.log('InformationCard.editCompleteWithOptions', options, window.location.href)
      if ('addAnotherStudent' in options && options.addAnotherStudent) {
        console.log('InformationCard.editCompleteWithOptions addAnotherStudent', options, window.location.href)
        const newUrl = new URL('/students/add', window.location.origin)
        newUrl.searchParams.set('source_id', this.id)
        if (getQueryVariable('next')) {
          newUrl.searchParams.set('next', getQueryVariable('next'))
        }
        window.location.href = newUrl.href
        return true
      } else if ('goToProgram' in options && !isEmpty(options.goToProgram)) {
        const url = new URL('/' + options.goToProgram.slug, window.location.origin)
        if (this.information_card.school_year > 0) {
          url.searchParams.set('year', this.information_card.school_year)
        }
        window.location.href = url.href
        return true
      }
      return false
    },
    editComplete (payload) {
      console.log('InformationCard.editComplete payload', payload)
      if (this.isNew || this.next !== null) {
        this.id = payload.information_card_id
        let path = '/'
        let params = {}
        let url = '/'
        const vm = this
        if (this.next !== null) {
          console.log('InformationCard.editComplete this.next', this.next)
          switch (this.next) {
            case 'new_registration':
              params = {
                student_id: this.information_card.student_id,
                program: this.programSlug,
                year: this.information_card.school_year,
                information_card_id: this.information_card.id
              }
              console.log('InformationCard.editComplete new_registration', params)
              url = urlBuilder('/registrations/new', params)
              this.redirectTitle =
                'create registration with ' + this.information_card.name
              this.$modal.show('redirect-wait')
              window.location.assign(url)
              break
            case 'student_list':
              params = {
                student_id: this.information_card.student_id
              }
              console.log('InformationCard.editComplete student_list', params)
              url = urlBuilder('/students', params)
              if (!this.editCompleteWithOptions(payload)) {
                this.redirectTitle = 'your students listing page'
                this.$modal.show('redirect-wait')
                window.location.assign(url)
              }

              break
            case 'program':
              params = {
                year: this.information_card.school_year,
                student_id: this.information_card.student_id
              }
              console.log('InformationCard.editComplete program', payload)
              if (!isEmpty(this.programSlug)) {
                params.program = this.programSlug
                url = urlBuilder('/' + this.programSlug, params)
                this.redirectTitle = this.program.name
              } else {
                this.redirectTitle = 'Home Page'
                url = urlBuilder('/')
              }
              if (!this.editCompleteWithOptions(payload)) {
                console.log('InformationCard.editComplete student_list', payload)
                this.$modal.show('redirect-wait')
                window.location.assign(url)
              }
              break
            case 'add_to_registration': {
              const params = {
                student_id: this.information_card.student_id,
                registration_id: this.registration_id,
                member_id: this.member_id,
                program: this.programSlug,
                information_card_id: this.id,
                year: this.year
              }
              console.log('InformationCard.editComplete add_to_registration', params)
              const path =
                '/programs/' + this.program.slug + '/registrations/add-student'
              API.put(path, params)
                .then(function (response) {
                  const data = response.data
                  // eslint-disable-next-line no-console
                  console.log(response)
                  if (typeof data !== 'undefined') {
                    const result = data.result
                    if (
                      typeof result.isError !== 'undefined' &&
                      result.isError === false
                    ) {
                      const updatedStudent = data.student
                      vm.$notify({
                        group: 'enrollment',
                        type: 'success',
                        title: updatedStudent.name + ' updated',
                        text:
                          updatedStudent.name + ' updated with your changes.',
                        duration: 2000,
                        speed: 1000
                      })
                      const program = vm.program
                      if ('addAnotherStudent' in payload && payload.addAnotherStudent) {
                        console.log('InformationCard.editComplete addAnotherStudent', payload)
                        const newUrl = new URL(window.location.href)
                        newUrl.searchParams.set('source_id', vm.id)
                        window.location.href = newUrl.href
                      } else if ('goToProgram' in payload && !isEmpty(payload.goToProgram)) {
                        window.location.href = '/' + payload.goToProgram.slug
                      } else {
                        if (program.require_enroll) {
                          vm.redirectTitle =
                            'enroll in class or placement for ' + vm.program.name
                          vm.$modal.show('redirect-wait')
                          window.location.href = urlBuilder(
                            '/' + program.slug + '/sessions/registering',
                            params
                          )
                        } else {
                          if (!isEmpty(vm.returnTo)) {
                            window.location.href = vm.returnTo
                          } else {
                            vm.redirectTitle = vm.program.name
                            vm.$modal.show('redirect-wait')
                            window.location.href = urlBuilder(
                              '/registrations/view',
                              params
                            )
                          }
                        }
                      }
                    } else if (result.isError === true) {
                      if (typeof result.redirect !== 'undefined') {
                        var returnTo = window.location.pathname
                        vm.$modal.show('redirect-wait')
                        window.location.href = urlBuilder(result.redirect, {
                          return_to: returnTo
                        })
                      }
                      if (typeof result.message !== 'undefined') {
                        alert(result.message)
                      }
                    }
                  } else {
                    this.switchMode('view')
                  }
                })
                .catch(function (e) {
                  vm.$notify({
                    group: 'enrollment',
                    type: 'error',
                    title: 'Problem adding to registration',
                    text: e.message,
                    duration: 2000,
                    speed: 1000
                  })
                  vm.$modal.show('redirect-wait')
                  console.error('InformationCard.editComplete', e)
                  window.location.assign('/')
                })
              break
            }
            case 'return_to':
              path = this.returnTo
              this.redirectTitle = 'returning...'
              console.log('InformationCard.editComplete return_to', payload)
              if (!this.editCompleteWithOptions(payload)) {
                this.$modal.show('redirect-wait')
                window.location = urlBuilder(path)
              }
              break
            default:
              console.log('InformationCard.editComplete default', payload)
              this.redirectTitle = 'Home Page'
              url = urlBuilder('/')
              if (!this.editCompleteWithOptions(payload)) {
                this.$modal.show('redirect-wait')
                window.location.href = urlBuilder('/')
              }
          }

          // setTimeout(function(){document.location.href = url},50);
        } else if (this.return_to !== null) {
          path = this.return_to
          this.$modal.show('redirect-wait')
          if (!this.editCompleteWithOptions(payload)) {
            window.location = urlBuilder(path)
          }
        } else {
          this.switchMode('view')
          if (!this.editCompleteWithOptions(payload)) {
            this.$modal.show('next-view')
          }
        }
      } else {
        if (!this.editCompleteWithOptions(payload)) {
          this.switchMode('view')
        }
      }
    },
    cancel () {
      this.back()
    },
    back: function () {
      let path = '/'
      if (this.returnTo !== null) {
        path = this.returnTo
      }
      window.location.href = urlBuilder(path)
    }
  }
}
</script>

<style scoped></style>
