import { MANAGER_API, API } from '@/utilities/http-common'

const getInformationCard = id => {
  return API.get(`/information_cards/${id}`).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.information_card
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

export {
  getInformationCard
}
